
  import React from "react"
  import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'

  import arrowTop from "../images/arrow-top.png"

  const ConceptView = (props) => {
    const { t } = useTranslation()

    return (<div>
  <div id="Team" className="section _0px-top wf-section">
    <div className="container">
      <h1 className="heading-3"><Trans>Notre concept</Trans></h1>
      <div className="display-wrapper">
        <h3 className="heading-2"><em className="italic-text sub-tile-concept"><Trans>Nous sommes partis de 3 constats</Trans></em></h3>
        <div className="div-block-9">
          <p className="paragraph-10 constat-concept"><span className="icon-concept">1️⃣&nbsp;</span><br />‍<br /><Trans>La crise du Covid a profondément changé nos existences nos habitudes et nos modes de consommation.</Trans><br /></p>
          <p className="paragraph-10 constat-concept"><span className="icon-concept">2️⃣&nbsp;</span>&nbsp;<br />‍<br /><Trans>Le e-commerce déjà développé à considérablement exploser. </Trans><br />‍<br /><Trans>Les commerces ne misant que sur la vente physique ont perdu de nombreuses ventes.</Trans></p>
          <p className="paragraph-10 constat-concept"><span className="icon-concept">3️⃣&nbsp;&nbsp;<br /></span>‍<br /><Trans>De nombreux vignerons n'ont pas encore pris le train du e-commerce.</Trans><br /><br /><Trans>Se fermant des possibilités pour vendre et faire connaître leur vin.</Trans><br /></p>
        </div>
        <h3 className="heading-2 main-concept"><Trans>Cause ? 🤔</Trans></h3>
        <p className="paragraph-10"><Trans>❌&nbsp;&nbsp; Un coût encore trop important pour la création, l'hébergement et la maintenance.&nbsp;La crise du Covid freinant encore plus l'investissement.</Trans><br /><br /><Trans>❌ &nbsp; Des agences, qui ne sont pas liés à la performance des sites qu'ils conçoivent. Ils n'ont pas besoin de faire en sorte que leurs sites soient les plus performants, les plus vendeurs, car ils sont payés mensuellement qu'importe leurs résultats.</Trans><br /><br /><Trans>❌ &nbsp; Une fois que le site est réalisé, il y a tout autour un écosystème à mettre en place pour que la vente se passe bien du côté client et du côté vigneron. </Trans><br /><br /><Trans>❌ &nbsp; Un système simple et efficace doit être mis en place pour gérer les commandes.</Trans></p>
        <h3 className="heading-2 main-concept"><Trans>Notre Solution,&nbsp;Un partenariat 🤝</Trans></h3>
        <p className="paragraph-10"><Trans>✅&nbsp; &nbsp;Nous avons conçus une offre, où si nous sommes gagnants c'est que vous l'êtes déjà. </Trans><br /><br /><Trans>✅ &nbsp;&nbsp;Cette offre est </Trans><strong><Trans>gratuite, </Trans></strong><Trans> nous nous rémunérons exclusivement à la commission.</Trans><br /><br /><Trans>✅&nbsp;&nbsp;&nbsp;Si vous ne vendez pas, vous n'avez rien à nous devoir pour la création, l'hébergement et la maintenance du site.&nbsp;</Trans><br /><br /><Trans>✅ &nbsp;&nbsp;De la création du site à l'expédition du produit nous avons pensé globalement cette offre qui s'articule autour de 3 Axes</Trans><br /></p>
        <div className="div-block-9">
          <p className="paragraph-10 constat-concept"><span className="icon-concept">1️⃣</span> &nbsp;&nbsp;<br /><br /><Trans>Création d'un site marchand</Trans><br />‍<br />‍<br /></p>
          <p className="paragraph-10 constat-concept"><span className="icon-concept">2️⃣</span>&nbsp;<br /><br /><Trans>Mise en place de Frais de port plafonnés</Trans><br />‍</p>
          <p className="paragraph-10 constat-concept"><span className="icon-concept">3️⃣</span>&nbsp;&nbsp;&nbsp;<br /><br /><Trans>Accompagnement dans le Digital Marketing</Trans></p>
        </div>
        <h3 className="heading-2 main-concept"><Trans>Site Marchand 🛒</Trans></h3>
        <p className="paragraph-10"><Trans>📍 Nos sites se doivent d'être</Trans><strong><Trans> uniques, rapides et efficace</Trans></strong>.&nbsp;<br /><br /><Trans>📍 Ils sont uniques car ils ne se basent pas sur des modèles, comme les agences ont l'habitude de travailler, mais sur des design fait sur mesure par des graphistes.&nbsp;</Trans><br /><br /><Trans>📍 Nous prenons ce qui fait vos différences, vos demandes, vos contraintes et nous construisons sur-mesure un site marchand.&nbsp;La qualité du design est la première étape vers la conversion d'un visiteur en acheteur. </Trans><br /><br /><Trans>📍 Nos sites sont extrêmement rapides, car ils sont construits autour de nouvelles technologies dans le développement web et aussi car ils répondent à un besoin fort d'optimisation. En effet Google regarde attentivement la vitesse de chargement pour placer ou non un site en première page.&nbsp;C'est donc un indicateur à regarder attentivement.&nbsp;</Trans><br /><br /><Trans>📍 Enfin nos sites sont Responsive, c'est à dire qu'ils s'adaptent naturellement sur mobile.&nbsp;Aujourd'hui de plus en gens consultent et achètent sur mobile.</Trans></p>
        <h3 className="heading-2 main-concept"><Trans>Frais de port ✈️</Trans></h3>
        <p className="paragraph-10"><Trans>📍 Pour nous les frais de port sont le nerf du e-commerce ! </Trans><br /><br /><Trans>📍 Ils se doivent d'être les plus bas possible.&nbsp;</Trans><br />‍<br /><Trans>📍 Nous avons été horrifié d'apprendre que certaines expéditions coûtaient plus chère que la valeur du vin elle même ! </Trans><br /><br /><Trans>📍 Notre règle d'or, est d'avoir des frais de port de 10 € Maximum.&nbsp;</Trans><br /><br /><Trans>📍 Ça vous semble infaisable ?&nbsp;Alors contactez-nous pour en savoir plus !</Trans></p>
        <h3 className="heading-2 main-concept"><Trans>Digital Marketing &nbsp;🛍</Trans></h3>
        <p className="paragraph-10"><Trans>📍 Une fois que le site est développé il faut le faire connaitre ! </Trans><br /><br /><Trans>📍 Un des meilleurs outils est Google Ads.&nbsp;En un mot il permet de positionner votre site web dans la partie annonce des résultats de Google.</Trans><br />‍<br /><Trans>📍&nbsp;Si son concept peut sembler simple, sa mise en place et surtout son optimisation est complexe. </Trans><br /><br /><Trans>📍&nbsp;Cette offre ne serait pas complète et les sites ne seraient pas bien placer dans Google Ads.&nbsp;Nous demandons donc un budget marketing, que nous investirons gratuitement dans Google Ads.&nbsp;</Trans><br /><br /><Trans>📍&nbsp;En moyenne on estime, qu' 1 euro dépensé dans Google Ads génère 2&nbsp;euros de vente.&nbsp;À condition bien sûr que l'outil soit correctement maîtrisé.&nbsp;</Trans></p>
      </div>
      <div className="display-wrapper">
        <h1 className="heading-3"><Trans>Pourquoi un site ?</Trans></h1>
        <div className="paragraph-10 w-richtext">
          <p><Trans>Chez Interstellar, nous croyons qu'un site web est un véritable tremplin pour votre activité, une nouvelle façon de voir le commerce.</Trans><br /><br /><Trans>Comment un site marchand peut-il vous aider à vendre plus ?&nbsp;</Trans><br /><br /><strong><Trans>1️⃣ &nbsp;Il est ouvert tout le temps et est disponible n'importe où &nbsp;🌍</Trans><br /></strong><br /><Trans>Contraire ment à une boutique physique, votre site web peut générer des ventes, répondre aux questions de vos clients lorsque vous êtes absent. Il s'astreint aussi des limites géographiques, votre boutique en ligne et aussi disponible à vos fans japonais ! </Trans><br />‍<br /></p>
          <p><strong><Trans>2️⃣ &nbsp;Il est un argument de vente&nbsp; 🛍</Trans><br /></strong><br /><Trans>Avoir un (beau) site web, met en avant votre marque, permet de rassurer le client et lui donner envie d'acheter chez vous. Il augmente la notoriété et la valeur de votre marque.&nbsp;Tout comme la publicité, un client qui hésite entre deux produits, prendra celui qui lui fait le plus envie.</Trans><br />‍<br /><br /><strong><Trans>3️⃣ &nbsp;Il vous permet de toucher une autre clientèle &nbsp;⛩</Trans><br /></strong><br /><Trans>En 2020 nous estimons que 1 internautes sur 10 achètent un produit ou un service via un site e-commerce, tous supports confondus, soit 40 millions de français. Ce chiffre est aussi en hausse (+ 800 000 en un an). Des clients qui n'auraient jamais achetés chez vous, à cause de la distance par exemple, peuvent facilement le faire maintenant.</Trans><br /><br /><strong><Trans>4️⃣ &nbsp;Vous fixez vos règles &nbsp;⚖️</Trans><br /></strong>‍<br /><Trans>Votre site vous appartient, vous pouvez y faire ce que vous voulez.&nbsp;Vous souhaitez faire une offre flash ?&nbsp;Possible.&nbsp;Vous souhaitez tester un produit en pré-commande ?&nbsp;Possible.&nbsp;Vous souhaitez tester un nouvel article sur une durée de temps limité ?&nbsp;Possible.</Trans></p>
          <p>‍</p>
        </div>
      </div>
    </div>
  </div>
  <a href="#" style={{WebkitTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}} className="go-top w-inline-block"><img src={arrowTop} loading="lazy" width={256} alt="arrowTop" className="image" /></a>
</div>)
  };
  
  export default ConceptView
  