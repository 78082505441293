import React from 'react'
import { PageProps } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import ConceptView from '../views/concept.view.js'

const AgencyPage: React.FC<PageProps> = () => {
  const { t } = useTranslation('custom')
  return (
    <Layout seo={{ webflow: '5fea462298709637c6ba6395', title: t('Notre Agence Web à Bourgueil') }}>
      <ConceptView />
    </Layout>
  )
}

export default AgencyPage
